/**
 * NOTE: This file is ignored from git tracking. In a CI environment it is
 * generated by firebase-ci based on config in .firebaserc (see .travis.yml).
 * This is done so that environment specific settings can be applied.
 */

export const env = 'local'

export const firebase = {
  apiKey: "AIzaSyAf9GWajMA--nljhFrBnAZ_MeQiyfQAswA",
  authDomain: "vocacoord-prod.firebaseapp.com",
  databaseURL: "https://vocacoord-prod.firebaseio.com",
  projectId: "vocacoord-prod",
  storageBucket: "vocacoord-prod.appspot.com",
  messagingSenderId: "551057695742",
  appId: "1:551057695742:web:95563cd1499737560cd6af"
}

export const reduxFirebase = {
  enableLogging: false
}

export const publicVapidKey =
  'BF6YpO--fDPUtZpEEkY0zh-DRAjEA32bhSFElogsbL_KTECYkcIt0zdCLf7znI5z0HDkLf8bLz1pNZJsOFVU21Q'

export const sentryDsn =
  'https://7bdc42733dcc406c9227ea2a637e9421@sentry.io/1381805'

export default {
  env,
  firebase,
  reduxFirebase,
  sentryDsn,
  publicVapidKey
}
